import React,{useEffect} from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { connect } from "react-redux"
import {getPropertyAction} from '../../../redux/propertiesDucks'
import { getType, getOperations, getFakeAddres, getLocation } from "../../../helpers/helper.properties"
import { getCover } from "../../../helpers/helper.rendering"
import StickyTab from "../../../components/StickyTab";
import MobileStickyTab from "../../../components/mobileStickyTab";
import { navigate } from "gatsby"
var HTMLParser = require("node-html-parser")

const ClientSideOnlyLazy = React.lazy(() =>
  import("../../../components/Propiedad/main")
)
const ClientSideOnlyLazyAbout = React.lazy(() =>
  import('../../../components/Propiedad/about_prop')
)

//Components

const IndexPage = (props) => {

  const isSSR = typeof window === "undefined"

  var parsed = HTMLParser.parse(`<div></div>`)

  const {id} = props
  const {dispatch} = props
  const {api_key} = props
  // const {serverData} = props

  useEffect(() => {
    if (api_key && id) {
      dispatch(getPropertyAction(id)).catch(error => {
        console.error(error)
        if (error.response && error.response.status === 404) {
          navigate("/404")
        }
      })
    }
  }, [api_key, id])

  return (
    <>
      <Layout>
            {/* <Seo 
                title={getType(serverData) + " en "  + getOperations(serverData)[0] + " - " + getFakeAddres(serverData)}
                meta={"Encontrá la propiedad que estabas buscando. " + getFakeAddres(serverData) + " en " + getOperations(serverData)[0] +  " en " +  getLocation(serverData)} 
                description={"Encontrá la propiedad que estabas buscando. " + getFakeAddres(serverData) + " en " + getOperations(serverData)[0] +  " en " +  getLocation(serverData)} 
                image={getCover(serverData.photos)?.original}
            /> */}
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <ClientSideOnlyLazy id={id} />
              </React.Suspense>
            )}
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                {/* No borrar este Article */}
                <article>
                  <div className="row bg-white">
                      <div className="col-12 col-lg-8">
                        <ClientSideOnlyLazyAbout />
                      </div>
                      <div className="d-none d-lg-block col-lg-4 pt-2">
                        <StickyTab/>
                      </div>
                  </div>
                  <MobileStickyTab/>
                </article>
              </React.Suspense>
            )}
      </Layout>
    </>
  )
}

export default connect(state => ({
    api_key: state.settings.keys.tokko
}),null)(IndexPage);

// export async function getServerData(context) {
//     try {
//       const res = await fetch(`https://api-mh.mediahaus.com.ar/api/v1/tokko/properties/${context.params.id}?cache=true`,{headers: {"X-TOKKO-KEY": "4aeb6e6d5e8f5f041734390ce1a2a65238328ae9"}})
//       if (!res.ok) {
//         throw new Error(`Response failed`)
//       }
//       return {
//         props: await res.json(),
//       }
//     } catch (error) {
//       return {
//         status: 500,
//         headers: {},
//         props: {}
//       }
//     }
// }
  